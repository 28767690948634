import { makeStyles } from '@material-ui/core/styles';
import { RoomScene } from '@roomie-engineering/room-viewer';
import { useState, useContext } from 'react';
import styled from 'styled-components';
import {
  CustomIconButton,
  SemiCircularButtonPair,
  CustomButton,
  Measurement,
  ThreeD,
  TwoD,
  Zoom_in,
  Zoom_out,
  Person,
} from 'components';
import { AuthContext } from 'context/authContext';
import Colors from 'theme';
import { SUCCESS } from 'utils';
import { Params } from 'api';
import { AxiosResponse } from 'axios';

interface Flags {
  measurementActive: boolean;
  measurementHovered: boolean;
  zoomInPressed: boolean;
  zoomInHovered: boolean;
  zoomOutPressed: boolean;
  zoomOutHovered: boolean;
  twoDActive: boolean;
  twoDHovered: boolean;
  threeDActive: boolean;
  threeDHovered: boolean;
  personHovered: boolean;
  cartPressed: boolean;
  cartHovered: boolean;
  PBActive: boolean;
  PBHovered: boolean;
  LJActive: boolean;
  LJHovered: boolean;
}

interface ButtonBarTopProps {
  roomScene: RoomScene | null;
  activateMeasurement: boolean;
  viewer360?: string;
  showSignOutButton?: boolean;
  showShareButton?: boolean;
  shareClicked?: () => void;
  show3DPhotoViewer?: () => void;
  setInviteModal?: (newVal: boolean) => void;
  logout: (
    params: Params | FormData,
    onCompleted?: ((res: AxiosResponse<any>) => void) | undefined,
    postParams?: boolean | undefined,
  ) => void;
}

const ButtonBarTop = ({
  roomScene,
  showSignOutButton = false,
  showShareButton = false,
  shareClicked,
  show3DPhotoViewer,
  //viewer360 = 'http://pixelatedramblings.com/test/roomie-poc/viewerbjs.html',
  ...props
}: ButtonBarTopProps): JSX.Element => {
  const authContext = useContext(AuthContext);
  const [flags, setFlags] = useState<Flags>({
    measurementActive: props.activateMeasurement,
    measurementHovered: false,
    zoomInPressed: false,
    zoomInHovered: false,
    zoomOutPressed: false,
    zoomOutHovered: false,
    twoDActive: false,
    twoDHovered: false,
    threeDActive: true,
    threeDHovered: false,
    personHovered: false,
    cartPressed: false,
    cartHovered: false,
    PBActive: true,
    PBHovered: false,
    LJActive: false,
    LJHovered: false,
  });

  const setValueInFlags = (key: string, value: boolean) => {
    setFlags((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const measurementPressed = () => {
    setValueInFlags('measurementActive', true);
    roomScene?.measureTool.activate();
  };

  const zoomInPressed = () => {
    roomScene?.zoomCamera(-1);
  };

  const zoomOutPressed = () => {
    roomScene?.zoomCamera(1);
  };

  const twoDPressed = () => {
    setValueInFlags('threeDActive', false);
    setValueInFlags('twoDActive', true);
    roomScene?.switchRoomViewTo2D();
  };

  const threeDPressed = () => {
    setValueInFlags('threeDActive', true);
    setValueInFlags('twoDActive', false);
    roomScene?.switchRoomViewTo3D();
  };

  const logout = async () => {
    await props.logout({}, (res) => {
      if (res.data.reason === SUCCESS) authContext?.logout();
    });
  };

  const classes = ButtonBarTopStyles();

  return (
    <Container>
      <div className={classes.ButtonBarTop}>
        <div className={classes.measurementButton}>
          <CustomIconButton
            buttonPressed={() => measurementPressed()}
            setActiveValue={(item) => setValueInFlags('measurementHovered', item)}
            active={props.activateMeasurement ?? flags.measurementActive ?? flags.measurementHovered}
            icon={
              <Measurement
                fill={
                  (props.activateMeasurement ?? flags.measurementActive ? true : flags.measurementHovered)
                    ? Colors.plain_white
                    : Colors.icon_primary
                }
              />
            }
          />
        </div>
        <SemiCircularButtonPair
          buttonPressedL={() => zoomInPressed()}
          setActiveValueL={(item) => setValueInFlags('zoomInHovered', item)}
          buttonPressedR={() => zoomOutPressed()}
          setActiveValueR={(item) => setValueInFlags('zoomOutHovered', item)}
          iconR={
            <Zoom_out
              fill={(flags.zoomOutPressed ? true : flags.zoomOutHovered) ? Colors.plain_white : Colors.icon_primary}
            />
          }
          activeR={flags.zoomOutPressed ?? flags.zoomOutHovered}
          iconL={
            <Zoom_in
              fill={(flags.zoomInPressed ? true : flags.zoomInHovered) ? Colors.plain_white : Colors.icon_primary}
            />
          }
          activeL={flags.zoomInPressed ?? flags.zoomInHovered}
        />
        <SemiCircularButtonPair
          buttonPressedL={() => twoDPressed()}
          setActiveValueL={(item) => setValueInFlags('twoDHovered', item)}
          buttonPressedR={() => threeDPressed()}
          setActiveValueR={(item) => setValueInFlags('threeDHovered', item)}
          iconR={
            <ThreeD
              fill={(flags.threeDActive ? true : flags.threeDHovered) ? Colors.plain_white : Colors.icon_primary}
            />
          }
          activeR={flags.threeDActive ?? flags.threeDHovered}
          iconL={
            <TwoD fill={(flags.twoDActive ? true : flags.twoDHovered) ? Colors.plain_white : Colors.icon_primary} />
          }
          activeL={flags.twoDActive ?? flags.twoDHovered}
        />
        <div className={classes.measurementButton}>
          <CustomIconButton
            buttonPressed={() => show3DPhotoViewer && show3DPhotoViewer()}
            setActiveValue={(item) => setValueInFlags('personHovered', item)}
            active={flags.personHovered}
            icon={<Person fill={flags.personHovered ? Colors.plain_white : Colors.icon_primary} />}
          />
        </div>
        {showShareButton && (
          <CustomButton
            title="Invite"
            width="88px"
            height="42px"
            borderRadius="4px"
            mTop="4px"
            background={Colors.paleTurquoise}
            textColor={Colors.hilighted_text_secondary}
            buttonClicked={() => shareClicked && shareClicked()}
          />
        )}
        {showSignOutButton && (
          <CustomButton
            title="Log out"
            width="130px"
            height="50px"
            borderRadius="34px"
            mTop="0px"
            mBottom="21px"
            background={Colors.plain_white}
            textColor={Colors.cyprus}
            border={`1px solid ${Colors.frenchPass}`}
            buttonClicked={async () => await logout()}
          />
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  .MuiSelect-root,
  .MuiSelect-select,
  .MuiSelect-selectMenu,
  .MuiInputBase-input,
  .MuiInput-input {
    padding: 10px !important;
  }
`;

const ButtonBarTopStyles = makeStyles(() => ({
  ButtonBarTop: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '46rem',
    paddingTop: '19px',
    paddingLeft: '10px',
    paddingRight: '10px',
    margin: '0px 24px 0px auto',
    zIndex: 10,
    transition: 'transform 8s',

    '@media (max-width: 1040px)': {
      justifyContent: 'Center',
      width: 'fit-content',
      alignItems: 'center',
      rowGap: '10px',
    },
    '@media (max-width: 464px)': {
      flexDirection: 'column',
    },
  },
  measurementButton: {
    marginRight: '10px',
  },
  select: {
    width: '200px !important',
    background: Colors.plain_white,
  },
  formControl: {
    minWidth: '120px',
    margin: '0px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default ButtonBarTop;
