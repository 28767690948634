import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Colors from 'theme';
import SyncIcon from '@material-ui/icons/Sync';
import useAxios, { ServerRoutes } from 'api';
import { GET, Messages, PRODUCT_SYNC_SUCCESS } from 'utils';
import { Dispatch, SetStateAction } from 'react';
import { Toast } from 'types';
import { Color } from '@material-ui/lab/Alert';

const useStyles = makeStyles(() => ({
  appBar: {
    position: 'fixed',
    left: '60px',
    backgroundColor: Colors.light_blue_bg,
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '62px',
  },
  button: {
    background: Colors.light_blue_bg,
  },
  syncButton: {},
  syncIcon: {
    fontSize: 25,
    marginRight: '2px',
  },
}));

interface NavbarProps {
  heading: string;
  setToast: Dispatch<SetStateAction<Toast | undefined>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const Navbar = ({ heading, setToast, setOpen }: NavbarProps) => {
  const classes = useStyles();
  const { callApi: syncProductCatalogApi } = useAxios(ServerRoutes.syncProductCatalog, GET, false, '', {}, 'shopping');

  const showToast = (message: string, severity: Color) => {
    setToast({
      severity: severity,
      message: message,
      autoHideDuration: 3000,
    });
  };

  const handleSync = async () => {
    await syncProductCatalogApi({}, async (res) => {
      if (res.status === 200) {
        showToast(PRODUCT_SYNC_SUCCESS, 'success');
      } else {
        showToast(Messages.PRODUCTS_SYNC_ERROR, 'error');
      }
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    });
  };

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Typography variant="h6">{heading}</Typography>
        <div>
          <IconButton onClick={handleSync} className={classes.syncButton}>
            <SyncIcon className={classes.syncIcon} />
            <Typography style={{ fontSize: '1.1rem' }}>Sync Product</Typography>
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
