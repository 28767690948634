import { useEffect, useRef, useState } from 'react';
import {
  RoomViewerButtonBarTop,
  ButtonBarBottom,
  RoomViewerOnScreenText,
  FloatingTooltip,
  ButtonBarLeft,
  ButtonBarLeftBottom,
} from 'components';
import {
  ItemModel,
  RoomScene,
  MovedEvent,
  Vector3,
  Scene,
  Engine,
  Vector2,
  ScreenAnchor,
  ViewMode,
  DistanceFormat,
  RoomModel,
} from '@roomie-engineering/room-viewer';
import LoadingIndicator from 'components/roomViewerComponents/LoadingIndicator';

interface Flags {
  showMeasureButton: boolean;
}

const RoomViewerContainer = (): JSX.Element => {
  let canvas: HTMLCanvasElement;
  let engine: Engine;
  let scene: Scene;
  let roomScene: RoomScene;

  const [roomSceneState, setRoomSceneState] = useState<RoomScene | null>(null);
  const [viewModeState, setViewModeState] = useState<ViewMode>(ViewMode.Room);
  const [selectedItemState, setSelectedItemState] = useState<ItemModel | undefined>();
  const [filename, setFileName] = useState('');

  const [TooltipXValue, setTooltipXValue] = useState<number>(0);
  const [TooltipYValue, setTooltipYValue] = useState<number>(0);
  const [TooltipTextValue, setTooltipTextValue] = useState<string>('');
  const [TooltipHiddenValue, setTooltipHiddenValue] = useState<boolean>(true);

  const [OnScreenTextValue, setOnScreenTextValue] = useState<string>('');

  const [flags, setFlags] = useState<Flags>({
    showMeasureButton: false,
  });

  const filenameRef = useRef(filename);

  const setValueInFlags = (key: string, value: boolean) => {
    setFlags((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const setSelectedFilename = (selectedFilename: string) => {
    setFileName(selectedFilename);
    filenameRef.current = selectedFilename;
  };

  const [showButtonBarBottom, setShowButtonBarBottom] = useState<boolean>(true);
  const toggleBottomBar = () => {
    setShowButtonBarBottom(!showButtonBarBottom);
  };

  const isLoading = () => {
    return roomSceneState?.selectedRoom?.isLoading() ?? false;
  };

  useEffect(() => {
    loadRoom();
    setRoomSceneState(roomScene);

    // the canvas/window resize event handler
    window.addEventListener('resize', resizeEventListener);
    window.addEventListener('keydown', keyPressEventListener);

    return function cleanUp() {
      window.removeEventListener('resize', resizeEventListener);
      window.removeEventListener('keydown', keyPressEventListener);
      cleanupRoom();
    };
  }, []);

  const resizeEventListener = () => {
    if (engine) {
      engine.resize();
    }
  };

  const keyPressEventListener = async (evt: KeyboardEvent) => {
    const selectedRoom = roomScene.selectedRoom;
    if (evt.key === ' ') {
      roomScene.toggleSelectedRoom();
    } else if (selectedRoom) {
      const selectedItem = selectedRoom.selectedItem;
      if (selectedItem) {
        const bedFeature = selectedItem.getBedFeature();
        if (bedFeature) {
          if (evt.key === 'ArrowUp' && !evt.shiftKey) bedFeature.adjustFrameHeight(true);
          else if (evt.key === 'ArrowDown' && !evt.shiftKey) bedFeature.adjustFrameHeight(false);
          else if (evt.key === 'ArrowUp' && evt.shiftKey) bedFeature.adjustFrameHeight(true, 1);
          else if (evt.key === 'ArrowDown' && evt.shiftKey) bedFeature.adjustFrameHeight(false, 1);
        }
      }
    }
  };

  const loadRoom = () => {
    // setup babylon
    canvas = document.getElementById('renderCanvas') as HTMLCanvasElement;
    engine = new Engine(canvas, true);
    scene = new Scene(engine);
    engine.runRenderLoop(function () {
      scene.render();
    });

    // setup room scene
    roomScene = new RoomScene({
      canvas,
      scene,
      onViewChanged: onViewChanged,
      onCameraMoved: onCameraMoved,
      onRoomSelected: onRoomSelected,
      onItemSelected: onItemSelected,
      onItemMoved: onItemMoved,
      onMeasureTool: onMeasureTool,
      distanceFormat: DistanceFormat.Inches,
      isDevMode: true,
    });

    // add startup room
    const room = roomScene.addRoom({
      title: 'Standard Double',
      assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/rooms/seattleu-campion-201-01.glb',
    });

    roomScene.setSelectedRoom(room);
  };

  const cleanupRoom = () => {
    if (engine) engine.dispose();
  };

  // callback for view changed
  const onViewChanged = (mode: ViewMode): void => {
    //console.log('camera changed mode: ' + mode);

    setViewModeState(mode);
  };

  // callback for camera moved
  const onCameraMoved = (event: MovedEvent): void => {
    //console.log('camera moved event: ' + event);

    if (event === MovedEvent.Delta || event === MovedEvent.End) {
      const selectedItem = roomScene?.selectedRoom?.selectedItem;
      if (selectedItem) updateTooltip(selectedItem);
    }
  };

  // callback for room selected
  const onRoomSelected = (room?: RoomModel): void => {
    //console.log('room=' + room?.title);
    setSelectedFilename(room?.title ?? '');
  };

  // callback for item selected
  const onItemSelected = (item?: ItemModel, groupItem?: ItemModel): void => {
    console.log('item selected event: item: %s groupItem: %s', item?.title, groupItem?.title);
    //const pointerItem = roomScene.selectedRoom?.pickItemAtPointer();
    //console.log('pointerItem: %s', pointerItem?.title);
    setSelectedItemState(item);
    updateTooltip(item);
  };

  // prettier-ignore
  // callback for item moved
  const onItemMoved = (event: MovedEvent, item: ItemModel, parent?: ItemModel, anchor?: string, position?: Vector3, rotation?: Vector3): void => {
      if(event === MovedEvent.End)
        console.log('item moved event: ' + event + ' item: ' + item.title +' parent: ' + parent?.title +' anchor: ' + anchor +' position: ' + position?.x + ',' + position?.y + ',' + position?.z + ' rotation: ' + rotation?.x + ',' + rotation?.y + ',' + rotation?.z);

      if(event === MovedEvent.Delta || event === MovedEvent.End) {
        updateTooltip(item);
      }
    };

  // callback for measure tool
  const onMeasureTool = (active: boolean, count?: number): void => {
    if (active) {
      setValueInFlags('showMeasureButton', true);
      count != undefined
        ? count == 0
          ? setOnScreenTextValue('Click another point to make a measurement')
          : setOnScreenTextValue('')
        : setOnScreenTextValue('Click anywhere to start a measurement');
    } else {
      setValueInFlags('showMeasureButton', false);
      setOnScreenTextValue('');
    }
  };

  const updateTooltip = (item?: ItemModel): void => {
    if (roomScene.isDebugOn() && item) {
      const variants = item.asset.variantsAvailable;
      //if(variants) console.log('available variants: ' + variants);
      let tooltip = item.title;
      if (variants) tooltip += '\n' + variants;
      setTooltipTextValue(tooltip);

      const screenPosition = item.getScreenPosition(ScreenAnchor.ScreenBoundsTop, new Vector2(0, -50));
      //console.log(screenPosition);
      setTooltipXValue(screenPosition.x);
      setTooltipYValue(screenPosition.y);

      setTooltipHiddenValue(false);
    } else {
      setTooltipHiddenValue(true);
    }
  };

  return (
    <>
      <LoadingIndicator isLoading={isLoading} />
      <canvas id="renderCanvas"></canvas>
      <FloatingTooltip x={TooltipXValue} y={TooltipYValue} text={TooltipTextValue} hidden={TooltipHiddenValue} />
      <RoomViewerButtonBarTop
        roomScene={roomSceneState}
        activateMeasurement={flags.showMeasureButton}
        filename={filename}
      />
      <RoomViewerOnScreenText textToDisplay={OnScreenTextValue} />
      <ButtonBarLeft roomScene={roomSceneState} toggleBottomBar={toggleBottomBar} />
      {showButtonBarBottom && (
        <ButtonBarBottom
          roomScene={roomSceneState}
          selectedItem={selectedItemState}
          viewMode={viewModeState}
          showMeasuremet={flags.showMeasureButton}
        />
      )}
      <ButtonBarLeftBottom roomScene={roomSceneState} />
    </>
  );
};

export default RoomViewerContainer;
