import { Button, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import useAxios, { ServerRoutes } from 'api';
import { AxiosResponse } from 'axios';
import Navbar from 'components/navbar/Navbar';
import MultiValuesInput from 'components/inputs/MultiValuesInput';
import { ChangeEvent, useEffect, useState } from 'react';
import useStyles from 'styles/ProductStyles';
import { Catalog, Combination, Geometry, Material, Media, Toast } from 'types';
import { CATALOG_UPDATED, GET, Messages, PRODUCT_UPLOAD_SUCCESS, PUT, POST } from 'utils';
import { Color } from '@material-ui/lab/Alert';
import { Snackbar } from 'components';
import Divider from '@material-ui/core/Divider';
import ProductCard from 'components/cards/ProductCard';
import { v4 } from 'uuid';

const Products = () => {
  const classes = useStyles();
  const [toast, setToast] = useState<Toast>();
  const [open, setOpen] = useState(false);
  const [catalogs, setCatalogs] = useState<Catalog[] | []>([]);

  const { callApi: updateCatalogsApi } = useAxios(ServerRoutes.productCatalogs, PUT, false, '', {}, 'shopping');

  const updateCatalogFromIndex = (catalogId: string, fieldToUpdate: string, updatedValue: any): void => {
    setCatalogs((prevCatalogs) => {
      return prevCatalogs.map((catalog) => {
        if (catalog.id === catalogId) {
          return {
            ...catalog,
            [fieldToUpdate]: updatedValue,
          };
        }
        return catalog;
      });
    });
  };

  const updateCombination = (catalogId: string, combinationId: string, fieldToUpdate: 'active', active: boolean) => {
    setCatalogs((prevCatalog) => {
      return prevCatalog.map((catalogItem) => {
        if (catalogItem.id !== catalogId) {
          return catalogItem;
        }
        const updatedCombinations = catalogItem.combinations?.map((combination) => {
          if (combination.id === combinationId) {
            return {
              ...combination,
              [fieldToUpdate]: active,
            };
          }
          return combination;
        });
        return {
          ...catalogItem,
          combinations: updatedCombinations,
        };
      });
    });
  };

  const updateCombinationMaterials = (
    catalogId: string,
    combinationId: string,
    materialId: string,
    fieldToUpdate: string,
    updatedValue: string,
  ) => {
    setCatalogs((prevCatalog) => {
      return prevCatalog.map((catalogItem) => {
        if (catalogItem.id !== catalogId) {
          return catalogItem;
        }

        const updatedCombinations = catalogItem.combinations?.map((combination) => {
          if (combination.id !== combinationId) {
            return combination;
          }

          const updatedMaterials = combination.materials?.map((material) => {
            if (material.id === materialId) {
              return {
                ...material,
                [fieldToUpdate]: updatedValue,
              };
            }
            return material;
          });
          return {
            ...combination,
            materials: updatedMaterials,
          };
        });
        return {
          ...catalogItem,
          combinations: updatedCombinations,
        };
      });
    });
  };

  const updateCombinationGeometries = (
    catalogId: string,
    combinationId: string,
    geometryId: string,
    fieldToUpdate: string,
    updatedValue: string,
  ) => {
    setCatalogs((catalogs) =>
      catalogs.map((catalog) => {
        if (catalog.id !== catalogId) {
          return catalog;
        }

        const updatedCombinations = (catalog.combinations || []).map((combination) => {
          if (combination.id !== combinationId) {
            return combination;
          }

          const updatedGeometries = (combination.geometries || []).map((geometry) => {
            if (geometry.id !== geometryId) {
              return geometry;
            }

            return {
              ...geometry,
              [fieldToUpdate]: updatedValue,
            };
          });

          return {
            ...combination,
            geometries: updatedGeometries,
          };
        });

        return {
          ...catalog,
          combinations: updatedCombinations,
        };
      }),
    );
  };

  const updateCombinationMedias = (
    catalogId: string,
    combinationId: string,
    mediasId: string,
    fieldToUpdate: string,
    updatedValue: string | number,
  ) => {
    const updatedCatalogs = catalogs.map((catalog: Catalog) => {
      if (catalog.id !== catalogId) {
        return catalog;
      }

      const updatedCombinations = (catalog.combinations || []).map((combination: Combination) => {
        if (combination.id !== combinationId) {
          return combination;
        }

        const updatedMedias = (combination.medias || []).map((media: Media) => {
          if (media.id !== mediasId) {
            return media;
          }

          return {
            ...media,
            [fieldToUpdate]: updatedValue,
          };
        });

        return {
          ...combination,
          medias: updatedMedias,
        };
      });

      return {
        ...catalog,
        combinations: updatedCombinations,
      };
    });

    setCatalogs(updatedCatalogs);
  };

  const addNewMaterial = (catalogId: string, combinationId: string, material: Material) => {
    setCatalogs((prevCatalog) => {
      return prevCatalog.map((catalogItem) => {
        if (catalogItem.id !== catalogId) {
          return catalogItem;
        }

        const updatedCombinations = catalogItem.combinations?.map((combination) => {
          if (combination.id !== combinationId) {
            return combination;
          }

          const updatedMaterials = combination.materials ? [...combination.materials] : [];

          const id = v4();
          const newMaterial = {
            ...material,
            id: id,
          };

          updatedMaterials.push(newMaterial);

          return {
            ...combination,
            materials: updatedMaterials,
          };
        });

        return {
          ...catalogItem,
          combinations: updatedCombinations,
        };
      });
    });
  };

  const addNewGeometries = (catalogId: string, combinationId: string, geometry: Geometry) => {
    setCatalogs((prevCatalog) => {
      return prevCatalog.map((catalogItem) => {
        if (catalogItem.id !== catalogId) {
          return catalogItem;
        }

        const updatedCombinations = catalogItem.combinations?.map((combination) => {
          if (combination.id !== combinationId) {
            return combination;
          }

          const updatedGeometries = combination.geometries ? [...combination.geometries] : [];
          const id = v4();
          const newGeometry = {
            ...geometry,
            id: id,
          };
          updatedGeometries.push(newGeometry);
          return {
            ...combination,
            geometries: updatedGeometries,
          };
        });
        return {
          ...catalogItem,
          combinations: updatedCombinations,
        };
      });
    });
  };

  const addNewMedias = (catalogId: string, combinationId: string, medias: Media) => {
    setCatalogs((prevCatalog) => {
      return prevCatalog.map((catalogItem) => {
        if (catalogItem.id !== catalogId) {
          return catalogItem;
        }

        const updatedCombinations = catalogItem.combinations?.map((combination) => {
          if (combination.id !== combinationId) {
            return combination;
          }
          const updatedMedias = combination.medias ? [...combination.medias] : [];

          const id = v4();
          const newMedias = {
            ...medias,
            id: id,
          };
          updatedMedias.push(newMedias);
          return {
            ...combination,
            medias: updatedMedias,
          };
        });
        return {
          ...catalogItem,
          combinations: updatedCombinations,
        };
      });
    });
  };

  const removeAttribute = (catalogId: string, combinationId: string, attributeId: string, propertyName: string) => {
    const updatedCatalogs = catalogs.map((item: Catalog) => {
      if (item?.id === catalogId) {
        item?.combinations?.map((combination: any) => {
          if (combination?.id === combinationId) {
            combination[propertyName] = combination[propertyName]?.filter(
              (attribute: Media | Material | Geometry) => attribute?.id !== attributeId,
            );
          }
          return combination;
        });
      }
      return item;
    });
    setCatalogs(updatedCatalogs);
  };

  const updateCatalogs = async () => {
    if (catalogs && catalogs?.length > 0) {
      updateCatalogsApi({ catalogs }, (res) => {
        if (res.status === 200) {
          showToast(CATALOG_UPDATED, 'success');
          setCatalogs([]);
        }
      });
    }
  };

  const { callApi: uploadProductApi, error: uploadProductApiError } = useAxios(
    ServerRoutes.productCatalog,
    POST,
    false,
    '',
    {},
    'shopping',
  );
  const { callApi: downloadProductApi } = useAxios(ServerRoutes.productCatalog, GET, false, '', {}, 'shopping');

  useEffect(() => {
    if (uploadProductApiError?.response?.status === 500) {
      showToast(Messages.PRODUCTS_UPLOAD_ERROR, 'error');
      setOpen(true);
    }
  }, [uploadProductApiError]);

  const showToast = (message: string, severity: Color) => {
    setToast({
      severity: severity,
      message: message,
      autoHideDuration: 3000,
    });
  };

  const handleUpload = () => {
    const fileInput = document.getElementById('uploadFile') as HTMLInputElement | null;
    if (fileInput) {
      fileInput.click();
    }
  };

  const checkResponse = (res: AxiosResponse) => {
    if (res?.status === 200) {
      showToast(PRODUCT_UPLOAD_SUCCESS, 'success');
    }
    setOpen(true);
  };

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      await uploadProductApi(formData, async (res) => {
        checkResponse(res);
      });
    }
  };

  const handleDownload = async () => {
    await downloadProductApi({}, async (res) => {
      const byteData = res.data;
      const blob = new Blob([byteData], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'products.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    });
  };

  return (
    <div className={classes.root}>
      <Snackbar
        isOpen={open}
        setIsOpen={setOpen}
        autoHideDuration={toast?.autoHideDuration}
        severity={toast?.severity || 'success'}
        message={toast?.message || ''}
      />
      <div className={classes.manageProductWrapper}>
        <Navbar heading="Manage Products" setToast={setToast} setOpen={setOpen} />
        <div className={classes.productsContainer}>
          <div className={classes.heading}>
            <Typography variant="h5">Manage Products</Typography>
          </div>
          <div className={classes.heading}>
            <Typography variant="h6">Manage Products through CSV</Typography>
          </div>
          <p className={classes.paragraph}>
            Below are two buttons that can be utilized to update product details in the database. Clicking the download
            button will generate a CSV file containing all product catalog along with their combinations, model URLs,
            material URLs, and asset URLs. You can make any necessary modifications to this file and then upload the
            updated version using the upload button. Our system will internally parse the file and apply the specified
            changes to the database.
          </p>
          <p>
            The supported modifications currently include:
            <ul>
              <li>Type, SurfaceType, and Default Image for ProductCatalog.</li>
              <li>URL and Position for CombinationMedia.</li>
              <li>Name, MaterialName, and MaterialURL for Material.</li>
              <li>URL for Geometry.</li>
            </ul>
          </p>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              className={classes.downloadButton}
              onClick={handleDownload}
              startIcon={<GetAppIcon />}
            >
              Download CSV
            </Button>
            <Button
              variant="contained"
              className={classes.uploadButton}
              startIcon={<PublishIcon />}
              onClick={handleUpload}
            >
              Upload CSV
            </Button>
            <input
              type="file"
              id="uploadFile"
              accept=".txt, .csv"
              style={{ display: 'none' }}
              onChange={handleFileSelect}
            />
          </div>
          <Divider />
          <div className={classes.heading}>
            <Typography variant="h6">Manage Products through Catalogs</Typography>
          </div>
          <div className={classes.buttons}>
            <MultiValuesInput setCatalogs={setCatalogs} />
          </div>
          <div className={classes.heading}>
            <Typography variant="h6">Product Catalogs</Typography>
          </div>
          {catalogs &&
            catalogs?.map((catalog: Catalog) => {
              return (
                <>
                  <ProductCard
                    catalog={catalog}
                    key={catalog?.id}
                    handleCatalogFormChange={updateCatalogFromIndex}
                    handleCombinationMaterialFormChange={updateCombinationMaterials}
                    handleCombinationGeometryFormChange={updateCombinationGeometries}
                    handleCombinationMediasFormChange={updateCombinationMedias}
                    addNewMaterial={addNewMaterial}
                    addNewGeometries={addNewGeometries}
                    addNewMedias={addNewMedias}
                    removeAttribute={removeAttribute}
                    updateCombination={updateCombination}
                  />
                </>
              );
            })}
          {catalogs?.length > 0 && (
            <Button variant="contained" color="primary" onClick={updateCatalogs}>
              Update Catalogs
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Products;
